import { api } from "@/utils/api";
import { pick } from "~/utils/helpers";

export function finishTask(taskId, params) {
  const whitelist = "response";
  return api.put(
    `/tasks/${taskId}/finish`,
    params ? pick(params, whitelist) : undefined
  );
}

export function getTaxIdInfo(data) {
  return api.post(`/tax/info?recaptchaToken=${data.recaptchaToken}`, data);
}

export function updateTaxId(data) {
  return api.put("/user/taxid", data);
}

export function updateDocuments() {
  return api.put("/user/kyc_tin");
}

export function deleteTask(taskId) {
  return api.delete(`/tasks/${taskId}`);
}

export function getDelegateRequestMessage(delegateAddress) {
  return api
    .get(`/tasks/delegate-request?delegateAddress=${delegateAddress}`)
    .then((r) => r.message);
}

export function postDelegateRequest(params) {
  const whitelist = "company,message,signature,delegateAddress,type";
  return api.post("/tasks/delegate-request", pick(params, whitelist));
}

export default {
  finishTask,
  getDelegateRequestMessage,
  postDelegateRequest,
};
